
export default {
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      styles: {
        minWidth: null,
        width: null,
      },
    }
  },
  computed: {
    cardProps () {
      return {
        to: this.eoUrl,
        npoUrl: this.npoUrl,
        title: this.title,
        description: this.description,
        image: this.image,
        imageFull: true,
        imageSize: this.imageSize,
        minHeight: this.minHeight,
        titleLines: this.titleLines,
        style: this.styles,
      }
    },
    description () {
      return !this.isSmall && this.npoIntro ? `${this.npoIntro}` : null
    },
    eoUrl () {
      return this.$link.publication(this.publication)
    },
    image () {
      return this.publication?.program?.episodes?.[0]?.hero?.replace('{format}', 's1920') || this.publication?.hero || this.publication?.heroUrl || this.publication?.imageUrl
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374 sm:369 md:372 lg:407'
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === '' || this.isTiny
    },
    isTiny () {
      return this.$attrs.tiny || this.$attrs.tiny === ''
    },
    minHeight () {
      let height = 0
      if (this.isTiny) {
        height = this.$viewport.smAndUp ? '184px' : '125px'
      } else if (this.isSmall) {
        height = this.$viewport.smAndUp ? '250px' : '125px'
      } else {
        height = this.$viewport.smAndUp ? '384px' : '209px'
      }
      return this.$attrs['min-height'] || height
    },
    npoUrl () {
      return this.publication?.program?.episodes?.[0]?.externalUrl
    },
    npoTitle () {
      return this.publication?.program?.episodes?.[0]?.title
    },
    npoIntro () {
      return this.publication?.program?.episodes?.[0]?.intro
    },
    title () {
      return this.publication?.title
    },
    titleLines () {
      return this.$attrs['title-lines'] || this.isSmall ? 2 : 3
    },
  },
}
