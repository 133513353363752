
import { mapGetters } from 'vuex'
import { PROGRAM_ACTIONS, TITLES, EXTERNAL_LINKS } from '~/constants'
import { metaDataMixin } from '~/mixins'

const PROGRAM_CHANNEL_ACTION = 'program-channels/proxyAction'

export default {
  mixins: [
    metaDataMixin,
  ],
  async asyncData ({ $api, store, $config }) {
    const sectionWatchShortlyId = 'c0d476a5-0b50-4477-970d-c4f29303b416'
    const sectionWatchNowId = 'eae01969-7cfe-4865-86e3-6262fc7c2200'
    
    const [
      { data: { items: programsWatchNow = {}}},
      { data: { items: programsReality = {}}},
      { data: { items: programsFaith = {}}},
      { data: { items: programsActualiteit = {}}},
      { data: { items: programsSocial = {}}},
      { data: { items: programsDocumentaries = {}}},
      { data: { items: programsMusic = {}}},
      { data: { items: programsNature = {}}},
      { data: { items: programsKids = {}}},
      { data: { items: programsDrama = {}}},
      { data: { items: programsWatchShortly = {}}},
    ] = await Promise.all([
      $api.sections.getAllNested(
        'mixed', 
        sectionWatchNowId, 
        {
          domain: $config.preprEnvironmentDomainEo,
          limit: 5,
          offset: 0,
        },
      ),
      $api.programs.getAll({
        genre: "Reality",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Geloof",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Actualiteit",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Maatschappelijk",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Documentaire",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Muziek",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Natuur",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Kinderprogramma's",
        offset: 0,
        limit: 5,
      }),
      $api.programs.getAll({
        genre: "Dramaserie",
        offset: 0,
        limit: 5,
      }),
      $api.sections.getAllNested(
        'mixed', 
        sectionWatchShortlyId, 
        {
          domain: $config.preprEnvironmentDomainEo,
          limit: 5,
          offset: 0,
        },
      ),
    ])

    store.dispatch('breadcrumbs/set', {
      path: '/programmas' ,
      eoRoot: true,
      items: [
        {
          label: 'Programma\'s',
        },
      ],
    });

    await store.dispatch(PROGRAM_CHANNEL_ACTION, { action: PROGRAM_ACTIONS.ACTION_PROGRAM_FEATURED, offset: 0, limit: 1 })

    return {
      programsWatchNow,
      programsReality,
      programsFaith,
      programsActualiteit,
      programsSocial,
      programsDocumentaries,
      programsMusic,
      programsNature,
      programsKids,
      programsDrama,
      programsWatchShortly,
    }
  },
  data () {
    return {
      externalLinks: EXTERNAL_LINKS,
      seo: {
        title: TITLES.TITLE_PROGRAMMAS,
      },
      hero: null,
      searchInput: '',
      programsWatchNow: null,
      programsReality: null,
      programsFaith: null,
      programsActualiteit: null,
      programsSocial: null,
      programsDocumentaries: null,
      programsMusic: null,
      programsNature: null,
      programsKids: null,
      programsDrama: null,
      programsWatchShortly: null,
    }
  },
  computed: {
    hasNoResults () {
      return !this.televisionShows.length && !this.radioShows.length && !this.podcasts.length && !this.webseries.length && !this.programsTypeUnknown.length
    },
    ...mapGetters('loading', ['isLoading']),
  },
  methods: {
    search () {
      if (this.searchInput.length > 0) {
        this.searchInput.replace(/\s/g, '%20')
        this.$router.push(`/zoeken?invoer=${this.searchInput}&category=Kijken`)
        this.reset()
      }
    },
    reset () {
      this.searchInput = ''
    },
  },
}
